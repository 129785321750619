import { useContext } from 'react';

import { SnackbarContext } from '@providers/SnackbarProvider';
import { useIntl } from 'utils/intl';
import type { TrackEvent } from 'utils/analytics';
import useTrack from 'hooks/useTrack';

import messages from './useShare.messages';

type Props = {
  data: Required<Pick<ShareData, 'url' | 'title'>> & ShareData;
  infoMessage?: string;
  trackEventParams?: OmitLast<Parameters<TrackEvent>>;
};

const useShare = ({ data, infoMessage, trackEventParams }: Props) => {
  const intl = useIntl();
  const { trackEvent } = useTrack();
  const { showMessage } = useContext(SnackbarContext);

  const onShare = async () => {
    if ('navigator' in window) {
      if (navigator.share) {
        try {
          await navigator.share({ text: intl.formatMessage(messages.linkText), ...data });
        } catch (e) {} // eslint-disable-line no-empty
      } else if (navigator.clipboard) {
        await navigator.clipboard.writeText(data.url);
        showMessage('info', infoMessage || intl.formatMessage(messages.linkShared));
      }
    }

    if (trackEventParams) {
      trackEvent(...trackEventParams);
    }
  };

  return onShare;
};

export default useShare;
