import { ElementType } from 'react';

import getTextWithSpecialWordbreaks from 'utils/getTextWithSpecialWordbreaks';

type Props = {
  htmlText: string;
  component?: ElementType;
  withWordbreaks?: boolean;
};

// eslint-disable-next-line react/no-danger
const HtmlPreview = ({ htmlText, component: Component = 'div', withWordbreaks = false }: Props): JSX.Element => (
  <Component
    dangerouslySetInnerHTML={{
      __html: withWordbreaks ? getTextWithSpecialWordbreaks(htmlText) : htmlText,
    }}
  />
);

export default HtmlPreview;
