import { ElementType, ReactNode } from 'react';
import classNames from 'classnames';

import Message from 'types/Message';
import { Classes } from 'types/Classes';
import Typography from '@components/atoms/Typography';

import classes from './SectionHeader.module.scss';

type Props = {
  children: Message;
  classes?: Classes<'wrapper' | 'header' | 'title'>;
  actions?: ReactNode;
  renderAs?: ElementType;
};

const SectionHeader = ({ children, classes: customClasses, renderAs = 'h2', actions }: Props): JSX.Element => (
  <div className={classNames(classes.wrapper, customClasses?.wrapper)}>
    <div className={classNames(classes.header, customClasses?.header)}>
      {children && (
        <Typography variant="caption1" renderAs={renderAs} className={classNames(classes.title, customClasses?.title)}>
          {children}
        </Typography>
      )}
      {actions}
    </div>
  </div>
);

export default SectionHeader;
