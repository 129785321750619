import { useContext } from 'react';

import { SeoData } from 'types/models/SeoData';
import { ConfigContext } from '@providers/ConfigProvider';
import Typography from '@components/atoms/Typography';
import { FormattedMessage } from 'utils/intl';
import useShare from 'hooks/useShare';

import messages from './SeoShare.messages';
import classes from './SeoShare.module.scss';

export type Props = {
  href: string;
  slug: string;
  seoData?: SeoData;
};

const SeoShare = ({ href, seoData, slug }: Props): JSX.Element => {
  const { appUrl } = useContext(ConfigContext);
  const onShare = useShare({
    data: {
      title: seoData?.header || '',
      url: `${appUrl}${href.replace('[name]', slug)}`,
    },
  });

  return (
    <Typography variant="body2" display="inline" className={classes.share} onClick={onShare}>
      <FormattedMessage {...messages.share} />
    </Typography>
  );
};

export default SeoShare;
