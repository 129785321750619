/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['divider', 'paragraphWithCards', 'smallCards', 'link'] }] */
import { memo, useContext } from 'react';
import classNames from 'classnames';

import { Description as DescriptionType } from 'types/Description';
import { ConfigContext } from '@providers/ConfigProvider';

import { getDescription } from './Description.utils';
import classes from './Description.module.scss';

type Props = {
  data: DescriptionType;
  className?: string;
  renderFakeHeaders?: boolean;
  withoutLinks?: boolean;
  tableOfContentsLevels?: number[];
};

const Description = ({
  data,
  className,
  renderFakeHeaders = false,
  withoutLinks = false,
  tableOfContentsLevels = [],
}: Props): JSX.Element => {
  const { appUrl, contact } = useContext(ConfigContext);

  return (
    <div className={classNames(classes.wrapper, className)}>
      {getDescription({
        appUrl,
        contact,
        content: data.content,
        renderFakeHeaders,
        withoutLinks,
        tableOfContentsLevels,
      })}
    </div>
  );
};

export default memo(Description);
